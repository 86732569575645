import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  Contact,
  ContactForm,
} from "@bluefin/components";
import { Button, Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class VisitUsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Contact"}
              tagline={
                <Button
                  color={"red"}
                  as={"a"}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  href={"mailto:attorneydoyle@aol.com"}
                  content={"attorneydoyle@aol.com"}
                />
              }
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[fishermanBusiness.ctas.phone]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid stackable={true} className={"component-section-container"}>
            <Grid.Column width={16}>
              <Contact
                businessName={fishermanBusiness.name}
                phoneNumber={fishermanBusiness.primaryLocation.phoneNumber}
                email={fishermanBusiness.primaryLocation.email}
                hours={fishermanBusiness.primaryLocation.hours}
                address={{
                  street: fishermanBusiness.primaryLocation.street,
                  city: fishermanBusiness.primaryLocation.city,
                  state: fishermanBusiness.primaryLocation.state,
                  zip: fishermanBusiness.primaryLocation.zipCode,
                }}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
            </Grid.Column>
          </Grid>
          <Grid stackable={true} className={"component-section-container"}>
            <Grid.Row textAlign={"center"}>
              <Grid.Column width={16}>
                <h2>Directions</h2>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign={"left"}>
              <Grid.Column width={16}>
                <div>
                  <h2>FROM THE CITY - DIRECTIONS FROM THE LIE</h2>
                  <p>
                    Take the LIE to exit 33 which is Lakeville Road. At the
                    first light you will make a right (going south) this will be
                    Lakeview Road. Take Lakeview: 4.lights. ( You will go over
                    the Grand Central Parkway/Northern State Parkway) Once you
                    reach the 4th light you will make a left, this will be
                    Marcus Avenue (going east). Take Marcus Avenue 5 lights and
                    at the 5th light you will make a right into the parking lot
                    of 2001
                  </p>
                </div>
                <div>
                  <h2>FROM THE CITY - DIRECTIONS FROM THE GRAND CENTRAL</h2>
                  <p>
                    Take the Grand Central Parkway (East) to exit 25 which is
                    Lakeville Road/Marcus Avenue. At the first light (Marcus
                    Avenue) you will be making a left. Take Marcus 4 lights: and
                    at the 4th light make a right into our complex (2001).
                  </p>
                </div>
                <div>
                  <h2>
                    FROM LONG ISLAND - DIRECTIONS FROM THE NORTHERN STATE
                    PARKWAY
                  </h2>
                  <p>
                    Take the Northern State Parkway (West) to exit 25 which is
                    Lakeville Road/Marcus Avenue. At the first light (Marcus
                    Avenue), make a left turn (East). You will be going past 4
                    lights, at the 4th light make a right into the complex
                    (2001).
                  </p>
                </div>
                <div>
                  <h2>FROM LONG ISLAND DIRECTIONS - FROM THE LIE</h2>
                  <p>
                    Take the LIE (West) to exit 34 (South), this is New Hyde
                    Park Road. Go.4 lights (You will pass a Pathmark Shopping
                    Center on your right side), then make a right turn at the
                    4th light, this is Marcus Avenue (West), Make a left turn at
                    the 1st light into our complex (2001).
                  </p>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"component-section-container"}
          >
            <Grid.Column computer={12} mobile={16}>
              <ContactForm
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={"Let's Get In Touch"}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        phone {
          text
          link
          internal
        }
      }
      primaryLocation {
        phoneNumber
        email
        hours {
          open
          close
          label
          day
        }
        street
        city
        state
        zipCode
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Visit Us" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
